import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import styles from "./addDetails.module.css";
import { message } from "antd";
import LegalRepresentative from "./LegalRepresentative";
import Thanking from "./Thanking";
import CardComponent from "./CardComponent";
import AdDetails from "./AdDetails";
import Select from "react-select";
import LocationPicker from "../../components/Location/LocationPicker";
import BannerStyle from "./banner.module.css";
const SendRequest = () => {
  const navigate = useNavigate();

  const [location, setLocation] = useState("sendRequest");
  const [companyDetails, setCompanyDetails] = useState();
  const [addDetails, setAddDetails] = useState({});
  const [legalRepDetails, setLegalRepDetails] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [targetFrom, setTargetFrom] = useState("");
  const [targetTo, setTargetTo] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  // const [selectedAds, setSelectedAds] = useState("");
  console.log("address", address);
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  const handleTargetChange = (value) => {
    setTargetFrom(value);
    setTargetTo("");
  };
  const handleTargetChangeTo = (value) => {
    setTargetTo(value);
  };
  useEffect(() => {
    if (!!latitude && !!longitude) {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address));
    }
  }, [latitude, longitude]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "58px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "0.9rem",
      backgroundColor: "#f7fcff",
      fontFamily: "GalanoGrotesque-Medium",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
        paddingLeft: "8px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "45px", // Set the desired height for each option item
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      // fontWeight: '500',
      fontSize: "1rem",
      "@media (max-width: 767px)": {
        fontSize: "3vw",
        height: "7vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px", // Set the desired height for each option item
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "7vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };

  const dropdownOptions = [
    { value: "Restaurant", label: "Restaurant" },
    { value: "Bar", label: "Bar" },
    { value: "Coffeeshop", label: "Coffeeshop" },
  ];
  const optionsArray = [];

  for (let i = 18; i <= 60; i++) {
    optionsArray.push({ value: i.toString(), label: i.toString() });
  }
  const optionsArray2 = [];

  for (let i = targetFrom.value; i <= 60; i++) {
    optionsArray2.push({ value: i.toString(), label: i.toString() });
  }

  if (location === "adDetails") {
    return (
      <AdDetails
        setLocation={setLocation}
        companyDetails={companyDetails}
        legalRepDetails={legalRepDetails}
        addDetails={addDetails}
        setAddDetails={setAddDetails}
        // setSelectedAds={setSelectedAds}
        // selectedAds={selectedAds}
      />
    );
  }

  if (location === "legalRep") {
    return (
      <LegalRepresentative
        setLocation={setLocation}
        setLegalRepDetails={setLegalRepDetails}
        legalRepDetails={legalRepDetails}
        companyDetails={companyDetails}
        addDetails={addDetails}
        address={address}
        // selectedAds={selectedAds}
      />
    );
  }

  if (location === "thanking") {
    return <Thanking setLocation={setLocation} />;
  }
  console.log("userAddress", userAddress);
  const handleSubmit = (values) => {
    const companyNameRegex = /^[A-Za-z]+$/;
    console.log("values", values);
    const data = {
      companyName: values.companyName,
      // companyTaxNumber: values.companyTaxNumber,
      address: userAddress,
      businessEmail: values.businessEmail,
      // businessMobile: values.businessMobile,
      category: selectedOption.value,
      city: address.city,
      // targerGroupFrom: targetFrom.value,
      // targetGroupTo: targetTo.value,
    };

    if (!values.companyName) {
      message.error("Please enter company name");
    } else if (!/^[A-Za-z\s]+$/.test(values.companyName)) {
      message.error("Company name should contain only alphabetic characters");
    } else if (!selectedOption.value) {
      message.error("Please enter category");
    }
    // else if (!values.city) {
    //   message.error("Please enter city");
    // }
    // else if (!companyNameRegex.test(values.companyName)) {
    //   message.error("City name should contain only alphabetic characters");
    // }
    // else if (!values.companyTaxNumber) {
    //   message.error("Please enter company tax no.");
    // }
    else if (!values.businessEmail) {
      message.error("Please enter business email");
    }
    // else if (!values.businessMobile) {
    //   message.error("Please enter business mobile no.");
    // }
    // else if (!/^\d{7,15}$/.test(values.businessMobile)) {
    //   message.error("Phone number should have between 7 and 15 digits");
    // } else if (!targetTo.value) {
    //   message.error("Please enter target group");
    // }
    else {
      console.log("data", data);
      setCompanyDetails(data);
      setLocation("adDetails");
    }
  };

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>
          YOU WANT TO PLACE ADS WITH US?
        </div>
        <div className={BannerStyle.bannerSubTitle}>
          {" "}
          Expand your horizons for your business and place ads in the NETME App!{" "}
        </div>
      </div>
      <div className={styles.yourBenefits}>
        <p>Your benefits!</p>
      </div>
      <CardComponent />
      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              companyName: "",
              companyTaxNumber: "",
              address: "",
              businessEmail: "",
              businessMobile: "",
              category: "",
              location: "",
              city: "",
              postCode: "",
              houseNumber: "",
              targetGroup: "",
              ...companyDetails,
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={styles.headingMargin}>
                <div className={styles.headingLeft}>
                  <p className={styles.loc}>Send Request</p>
                  <div className={styles.underLineBorder}>
                    <img src="Images/underline.svg" alt="" />
                  </div>
                </div>
                <div className={styles.headingRight}>
                  <img src="Images/oneByThree.svg" alt="" />
                </div>
              </div>
              <div className={styles.location}>
                <div className={styles.location_a}>
                  <label className={styles.label}>
                    Company name<sup>*</sup>
                  </label>
                  <Field
                    name="companyName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Company Name"
                  ></Field>
                  <label className={styles.label}>
                    Address <sup>*</sup>
                  </label>
                  <div style={{ marginBottom: "1rem" }}></div>
                  {/* <br></br> */}
                  <LocationPicker
                    setLatitude={setLatitude}
                    latitude={latitude}
                    setLongitude={setLongitude}
                    longitude={longitude}
                    setUserAddress={setUserAddress}
                    userAddress={userAddress}
                  />
                  {/* <div style={{ marginTop: '1rem' }}></div> */}
                  <br />
                  {/* <label className={styles.label}>
                    Post Code<sup>*</sup>
                  </label>
                  <br></br>
                  <div></div>
                  <Field
                    name="postCode"
                    type="text"
                    className={`${styles.form_field} ${styles.marginField}`}
                    placeholder="Enter post code"
                    style={{ marginTop: "1vw" }}
                  ></Field>
                  <br/> 
                   <label className={styles.label}>
                    Company tax no<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="companyTaxNumber"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter company tax no"
                  ></Field> 
                   <br></br>
                  <label className={styles.label}>
                    Mobile number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="businessMobile"
                    type="number"
                    className={styles.form_field}
                    placeholder="Enter mobile number"
                  ></Field> */}
                </div>

                <div className={styles.location_b}>
                  <label className={styles.label}>
                    Category<sup>*</sup>
                  </label>
                  <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Select
                      options={dropdownOptions}
                      styles={customStyles}
                      value={selectedOption}
                      onChange={handleOptionChange}
                      placeholder="Select a category"
                    />
                  </div>
                  <label className={styles.label}>
                    E-mail<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="businessEmail"
                    type="email"
                    className={styles.form_field}
                    placeholder="Enter email"
                  ></Field>
                  {/* <label className={styles.label}>
                    House Number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="houseNumber"
                    type="text"
                    className={`${styles.form_field} ${styles.marginField}`}
                    placeholder="Enter house number"
                    style={{ marginTop: "1vw" }}
                  ></Field>
                  <br></br>

                  <label className={styles.label}>
                    City<sup>*</sup>
                  </label>
                  <br></br>

                  <Field
                    name="city"
                    type="text"
                    className={`${styles.form_field} ${styles.marginField}`}
                    placeholder="Enter city"
                    style={{ marginTop: "1vw" }}
                  ></Field>
                  <br></br> 
                   <br></br>
                  <label className={styles.label}>
                    Target group<sup>*</sup>
                  </label>
                  <br></br>
                  <div
                    style={{ marginTop: "1vw" }}
                    className={styles.targetDiv}
                  >
                    <p>Select age group:</p>
                    <span>
                      <Select
                        options={optionsArray}
                        styles={customStyles1}
                        value={targetFrom}
                        onChange={handleTargetChange}
                        placeholder="From"
                      />
                      <Select
                        options={optionsArray2}
                        styles={customStyles1}
                        value={targetTo}
                        onChange={handleTargetChangeTo}
                        isDisabled={!targetFrom.value}
                        placeholder="To"
                      />
                    </span>
                  </div> */}
                </div>
              </div>

              <div className={styles.form_btn}>
                <div></div>
                <button type="submit" onClick={() => handleSubmit}>
                  NEXT
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SendRequest;
