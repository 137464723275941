import React, { useState } from "react";
import styles from "./addDetails.module.css";
import { Formik, Form, Field } from "formik";
import { message } from "antd";
import CardComponent from "./CardComponent";
import Select from "react-select";
function AdDetails({
  setLocation,
  companyDetails,
  legalRepDetails,
  addDetails,
  setAddDetails,
}) {
  const [targetFrom, setTargetFrom] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [targetTo, setTargetTo] = useState("");
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  console.log("selectedOption", selectedOption);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "0..9rem",
      backgroundColor: "#f7fcff",
      fontFamily: "GalanoGrotesque-Medium",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px", // Set the desired height for each option item
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      "@media (max-width: 767px)": {
        fontSize: "3vw",
        height: "7vw",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };

  const dropdownOptions = [
    { value: "Feed", label: "Feed" },
    { value: "Suggestion", label: "Suggestion" },
    { value: "Fullscreen", label: "Fullscreen" },
  ];
  const handleTargetChange = (value) => {
    setTargetFrom(value);
    setTargetTo("");
  };
  const handleTargetChangeTo = (value) => {
    setTargetTo(value);
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px", // Set the desired height for each option item
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "7vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };

  const optionsArray = [];

  for (let i = 18; i <= 60; i++) {
    optionsArray.push({ value: i.toString(), label: i.toString() });
  }
  const optionsArray2 = [];

  for (let i = targetFrom.value; i <= 60; i++) {
    optionsArray2.push({ value: i.toString(), label: i.toString() });
  }
  console.log("addDetails", addDetails);
  const handleSubmit = async (values) => {
    // make form submission
    console.log("values", values);
    if (!values.title) {
      // message.error("Please enter title");
    } else if (!values.releaseDate) {
      message.error("Please select releaseDate");
    } else if (!values.body) {
      message.error("Please enter body");
    } else if (!selectedOption) {
      message.error("Please select ad type");
    } else {
      values.adType = selectedOption.value;
      values.targerGroupFrom = targetFrom.value;
      values.targetGroupTo = targetTo.value;
      setAddDetails(values);
      setLocation("legalRep");
    }
  };

  const handleBack = () => {
    setLocation("legalRep");
  };
  return (
    <div className={styles.container}>
      <div className={styles.form_pic_con}>
        {/* <div className={styles.form_pic}>
          <p id={styles.fpt1}>You want to place ads with us?</p>
          <p id={styles.fpt3}>
            Expand your horizons for your business and place ads in the NETME
            App!
          </p>
        </div> */}
        <img src="Images/adFormOne.svg" alt="" />
      </div>

      <div className={styles.yourBenefits}>
        <p>Your benefits!</p>
      </div>
      <CardComponent />

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              title: "",
              releaseDate: "",
              body: "",
              adType: selectedOption.value,
              ...addDetails,
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={styles.headingMargin}>
                <div className={styles.headingLeft}>
                  <p className={styles.l_r}>Ad details</p>
                  <img src="Images/underline.svg" alt="" />
                </div>
                <div className={styles.headingRight}>
                  <img src="Images/twoByThree.svg" alt="" />
                </div>
              </div>
              <div className={styles.legal}>
                <div className={styles.legal_a}>
                  <label className={styles.label}>
                    Add title<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="title"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Name"
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    Start date of the campaign<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="releaseDate"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    className={styles.form_field_date}
                  ></Field>
                  <br></br>
                  <br></br>
                  <label className={styles.label}>
                    Target group<sup>*</sup>
                  </label>
                  <br></br>
                  <div
                    style={{ marginTop: "1vw" }}
                    className={styles.targetDiv}
                  >
                    <p>Select age group:</p>
                    <span>
                      <Select
                        options={optionsArray}
                        styles={customStyles1}
                        value={targetFrom}
                        onChange={handleTargetChange}
                        placeholder="From"
                      />
                      <Select
                        options={optionsArray2}
                        styles={customStyles1}
                        value={targetTo}
                        onChange={handleTargetChangeTo}
                        isDisabled={!targetFrom.value}
                        placeholder="To"
                      />
                    </span>
                  </div>
                </div>

                <div className={styles.legal_b}>
                  <div style={{ marginTop: "1vw" }}>
                    <label className={styles.label}>
                      Ad Type<sup>*</sup>
                    </label>
                    <Select
                      options={dropdownOptions}
                      styles={customStyles}
                      value={selectedOption}
                      onChange={handleOptionChange}
                      placeholder="Select ad type"
                    />
                  </div>
                  <br></br>
                  <label className={styles.label}>
                    Body<sup>*</sup>
                  </label>

                  <br></br>
                  <Field
                    name="body"
                    component="textarea"
                    rows="5"
                    className={styles.form_fieldTextArea}
                  ></Field>
                </div>
              </div>
              <div className={styles.form_btn}>
                <button onClick={handleBack}>Back</button>
                <button type="submit" onClick={handleSubmit}>
                  NEXT
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AdDetails;
